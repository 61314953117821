var VanillaTilt = (function() {
  "use strict";
  var t = function(t, e) {
      if (!(t instanceof e))
        throw new TypeError("Cannot call a class as a function");
    },
    e = (function() {
      function e(i) {
        var s =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        if ((t(this, e), !(i instanceof Node)))
          throw "Can't initialize VanillaTilt because " + i + " is not a Node.";
        (this.width = null),
          (this.height = null),
          (this.left = null),
          (this.top = null),
          (this.transitionTimeout = null),
          (this.updateCall = null),
          (this.updateBind = this.update.bind(this)),
          (this.resetBind = this.reset.bind(this)),
          (this.element = i),
          (this.settings = this.extendSettings(s)),
          (this.reverse = this.settings.reverse ? -1 : 1),
          (this.glare = this.isSettingTrue(this.settings.glare)),
          (this.glarePrerender = this.isSettingTrue(
            this.settings["glare-prerender"]
          )),
          this.glare && this.prepareGlare(),
          this.addEventListeners();
      }
      return (
        (e.prototype.isSettingTrue = function(t) {
          return "" === t || !0 === t || 1 === t;
        }),
        (e.prototype.addEventListeners = function() {
          (this.onMouseEnterBind = this.onMouseEnter.bind(this)),
            (this.onMouseMoveBind = this.onMouseMove.bind(this)),
            (this.onMouseLeaveBind = this.onMouseLeave.bind(this)),
            (this.onWindowResizeBind = this.onWindowResizeBind.bind(this)),
            this.element.addEventListener("mouseenter", this.onMouseEnterBind),
            this.element.addEventListener("mousemove", this.onMouseMoveBind),
            this.element.addEventListener("mouseleave", this.onMouseLeaveBind),
            this.glare &&
              window.addEventListener("resize", this.onWindowResizeBind);
        }),
        (e.prototype.removeEventListeners = function() {
          this.element.removeEventListener("mouseenter", this.onMouseEnterBind),
            this.element.removeEventListener("mousemove", this.onMouseMoveBind),
            this.element.removeEventListener(
              "mouseleave",
              this.onMouseLeaveBind
            ),
            this.glare &&
              window.removeEventListener("resize", this.onWindowResizeBind);
        }),
        (e.prototype.destroy = function() {
          clearTimeout(this.transitionTimeout),
            null !== this.updateCall && cancelAnimationFrame(this.updateCall),
            this.reset(),
            this.removeEventListeners(),
            (this.element.vanillaTilt = null),
            delete this.element.vanillaTilt,
            (this.element = null);
        }),
        (e.prototype.onMouseEnter = function(t) {
          this.updateElementPosition(),
            (this.element.style.willChange = "transform"),
            this.setTransition();
        }),
        (e.prototype.onMouseMove = function(t) {
          null !== this.updateCall && cancelAnimationFrame(this.updateCall),
            (this.event = t),
            (this.updateCall = requestAnimationFrame(this.updateBind));
        }),
        (e.prototype.onMouseLeave = function(t) {
          this.setTransition(),
            this.settings.reset && requestAnimationFrame(this.resetBind);
        }),
        (e.prototype.reset = function() {
          (this.event = {
            pageX: this.left + this.width / 2,
            pageY: this.top + this.height / 2
          }),
            (this.element.style.transform =
              "perspective(" +
              this.settings.perspective +
              "px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)"),
            this.glare &&
              ((this.glareElement.style.transform =
                "rotate(180deg) translate(-50%, -50%)"),
              (this.glareElement.style.opacity = "0"));
        }),
        (e.prototype.getValues = function() {
          var t = (this.event.clientX - this.left) / this.width,
            e = (this.event.clientY - this.top) / this.height;
          return (
            (t = Math.min(Math.max(t, 0), 1)),
            (e = Math.min(Math.max(e, 0), 1)),
            {
              tiltX: (
                this.reverse *
                (this.settings.max / 2 - t * this.settings.max)
              ).toFixed(2),
              tiltY: (
                this.reverse *
                (e * this.settings.max - this.settings.max / 2)
              ).toFixed(2),
              percentageX: 100 * t,
              percentageY: 100 * e,
              angle:
                Math.atan2(
                  this.event.clientX - (this.left + this.width / 2),
                  -(this.event.clientY - (this.top + this.height / 2))
                ) *
                (180 / Math.PI)
            }
          );
        }),
        (e.prototype.updateElementPosition = function() {
          var t = this.element.getBoundingClientRect();
          (this.width = this.element.offsetWidth),
            (this.height = this.element.offsetHeight),
            (this.left = t.left),
            (this.top = t.top);
        }),
        (e.prototype.update = function() {
          var t = this.getValues();
          (this.element.style.transform =
            "perspective(" +
            this.settings.perspective +
            "px) rotateX(" +
            ("x" === this.settings.axis ? 0 : t.tiltY) +
            "deg) rotateY(" +
            ("y" === this.settings.axis ? 0 : t.tiltX) +
            "deg) scale3d(" +
            this.settings.scale +
            ", " +
            this.settings.scale +
            ", " +
            this.settings.scale +
            ")"),
            this.glare &&
              ((this.glareElement.style.transform =
                "rotate(" + t.angle + "deg) translate(-50%, -50%)"),
              (this.glareElement.style.opacity =
                "" + t.percentageY * this.settings["max-glare"] / 100)),
            this.element.dispatchEvent(
              new CustomEvent("tiltChange", { detail: t })
            ),
            (this.updateCall = null);
        }),
        (e.prototype.prepareGlare = function() {
          if (!this.glarePrerender) {
            var t = document.createElement("div");
            t.classList.add("js-tilt-glare");
            var e = document.createElement("div");
            e.classList.add("js-tilt-glare-inner"),
              t.appendChild(e),
              this.element.appendChild(t);
          }
          (this.glareElementWrapper = this.element.querySelector(
            ".js-tilt-glare"
          )),
            (this.glareElement = this.element.querySelector(
              ".js-tilt-glare-inner"
            )),
            this.glarePrerender ||
              (Object.assign(this.glareElementWrapper.style, {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                overflow: "hidden"
              }),
              Object.assign(this.glareElement.style, {
                position: "absolute",
                top: "50%",
                left: "50%",
                "pointer-events": "none",
                "background-image":
                  "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
                width: 2 * this.element.offsetWidth + "px",
                height: 2 * this.element.offsetWidth + "px",
                transform: "rotate(180deg) translate(-50%, -50%)",
                "transform-origin": "0% 0%",
                opacity: "0"
              }));
        }),
        (e.prototype.updateGlareSize = function() {
          Object.assign(this.glareElement.style, {
            width: "" + 2 * this.element.offsetWidth,
            height: "" + 2 * this.element.offsetWidth
          });
        }),
        (e.prototype.onWindowResizeBind = function() {
          this.updateGlareSize();
        }),
        (e.prototype.setTransition = function() {
          var t = this;
          clearTimeout(this.transitionTimeout),
            (this.element.style.transition =
              this.settings.speed + "ms " + this.settings.easing),
            this.glare &&
              (this.glareElement.style.transition =
                "opacity " +
                this.settings.speed +
                "ms " +
                this.settings.easing),
            (this.transitionTimeout = setTimeout(function() {
              (t.element.style.transition = ""),
                t.glare && (t.glareElement.style.transition = "");
            }, this.settings.speed));
        }),
        (e.prototype.extendSettings = function(t) {
          var e = {
              reverse: !1,
              max: 35,
              perspective: 1e3,
              easing: "cubic-bezier(.03,.98,.52,.99)",
              scale: "1",
              speed: "300",
              transition: !0,
              axis: null,
              glare: !1,
              "max-glare": 1,
              "glare-prerender": !1,
              reset: !0
            },
            i = {};
          for (var s in e)
            if (s in t) i[s] = t[s];
            else if (this.element.hasAttribute("data-tilt-" + s)) {
              var n = this.element.getAttribute("data-tilt-" + s);
              try {
                i[s] = JSON.parse(n);
              } catch (t) {
                i[s] = n;
              }
            } else i[s] = e[s];
          return i;
        }),
        (e.init = function(t, i) {
          t instanceof Node && (t = [t]),
            t instanceof NodeList && (t = [].slice.call(t)),
            t instanceof Array &&
              t.forEach(function(t) {
                "vanillaTilt" in t || (t.vanillaTilt = new e(t, i));
              });
        }),
        e
      );
    })();
  return (
    "undefined" != typeof document &&
      ((window.VanillaTilt = e),
      e.init(document.querySelectorAll("[data-tilt]"))),
    e
  );
})();
