import "./css/webflow.css";
import "./main.scss";
import "normalize.css";
import "animate.css";
import "wow.js";
import WOW from "wow.js";
import "./js/tilt.js";
import "aplayer/dist/APlayer.min.css";
import APlayer from "aplayer";

!(function(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function() {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(window, document, "script", "./js/events.js");
fbq("init", "160945777931642");
fbq("track", "PageView");

!(function(o, c) {
  var n = c.documentElement,
    t = " w-mod-";
  (n.className += t + "js"),
    ("ontouchstart" in o || (o.DocumentTouch && c instanceof DocumentTouch)) &&
      (n.className += t + "touch");
})(window, document);

var wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  offset: 0,
  mobile: true,
  live: true,
  callback: function(box) {},
  scrollContainer: null
});
wow.init();

const ap = new APlayer({
  container: document.getElementById("player"),
  fixed: true,
  autoplay: false,
  theme: "#dc885e",
  volume: 0.4,
  audio: [
    {
      name: "Reino animal",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/01.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "La última en reír",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/02.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Daré contigo",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/03.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Amor de Friki",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/04.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "El huracán",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/05.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Recuerdos Imborrables",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/06.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Humanoide",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/07.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Pude escribir una novela",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/08.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Realidad virtual",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/09.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "El último paseo",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/10.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    },
    {
      name: "Sanguijuela",
      artist: "El Beso del Escorpión",
      url: "./assets/mp3/11.mp3",
      cover: "./assets/mp3/cover_mini.jpeg"
    }
  ]
});

VanillaTilt.init(document.querySelectorAll(".carta-tilt"), {
  max: 5,
  speed: 400,
  glare: true,
  maxGlare: 0.7
});

document.querySelector(".buy-digital").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      value: 6.99,
      currency: "EUR",
      content_ids: "digital"
    });
  },
  false
);

document.querySelector(".buy-basica").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      value: 14.99,
      currency: "EUR",
      content_ids: "basica"
    });
  },
  false
);

document.querySelector(".buy-limitada").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      value: 24.99,
      currency: "EUR",
      content_ids: "limitada"
    });
  },
  false
);

document.querySelector(".buy-exclusiva").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      value: 39.99,
      currency: "EUR",
      content_ids: "exclusiva"
    });
  },
  false
);

document.querySelector(".buy-experiencia").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      value: 74.99,
      currency: "EUR",
      content_ids: "experiencia"
    });
  },
  false
);

document.querySelector(".buy-check").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      content_ids: "general"
    });
  },
  false
);

document.querySelector(".buy-cta").addEventListener(
  "click",
  function() {
    fbq("track", "AddToCart", {
      content_ids: "general"
    });
  },
  false
);
